import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'


const buybulks = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="qvm">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>Bulk Buys</h1>
          <span className="highlight"></span>
        </span>
        <span>Veritas Digital</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture">
		  	<img src={'/img/works/bulkbuys.svg'} alt="Bulk Buys" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
            <img className="picture" src={'/img/single/wordpress-icon.png'} width="139" height="87" alt="Wordpress" />
              <img className="picture" src={'/img/single/woocommerce-icon.png'} width="247" height="50" alt="Woocommerce" />
              <img className="picture" src={'/img/single/cart-icon.png'} width="57" height="48" alt="Cart" />
            </div>
            <a className="button visitwebsite" href="https://bulkbuys.com.au/" target="_blank" rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Visit Website</a> 

          </div>

        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>Online Shopping at Wholesale Direct Price for Commercial and Domestic.</p>
        <p>In collaboration with Veritas Digital's design vision to develop largest ecommerce and blog with Woocommerce platform. With optimised responsive for desktop, tablet and smartphones.</p>
      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/bulkbuys-desktop.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col">
          <img className="picture" src={'/img/single/bulkbuys-mobile.jpg'} alt="Mobile View" />
      </div>
      </div>

    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/we-make-online-videos" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">We Make Online Videos</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/early-learning-association-australia" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">ELAA</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default buybulks


export const Head = () => (
  <>
    <title>Buy Bulks - Tobstar</title>
<meta name="description" content="Online Shopping at Wholesale Direct Price for Commercial and Domestic." />
  </>
)